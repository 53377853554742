import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

const config = {
  apiKey: "AIzaSyA2F-IMF_yFc9aIQ98MCr0h9WmNedhD0Fc",
  authDomain: "pentabilities-prod.firebaseapp.com",
  databaseURL: "https://pentabilities-prod-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "pentabilities-prod",
  storageBucket: "pentabilities-prod.appspot.com",
  messagingSenderId: "561372573996",
  appId: "1:561372573996:web:db290eb8af4e8ff22cbc89",
  measurementId: "G-GCG4XVYHDH"
};

export const app = initializeApp(config);
export const firestore = getFirestore(app);
export const db = getDatabase(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);