import React, { useEffect, useState } from 'react'
import './App.css';
import _ from 'lodash';
import { Layout, Spin, Col, Row, Card, Tabs, Table, Tag, Modal, Typography, Result } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';


import { storage, functions } from "./_utilities/Firebase";
import { httpsCallable } from "firebase/functions";
import { ref, uploadBytes } from "firebase/storage";
import axios from 'axios';
import MyForm from './MyForm';

import logo from './img/logo.png';
import footer from './img/footer-1.png'


const { Title, Paragraph, Text, Link } = Typography;
const { Header, Footer, Sider, Content } = Layout;

const App = () => {

  const [appMode, setAppMode] = useState(1);
  const [school, setSchool] = useState(null);
  const [showLists, setShowLists] = useState(null);
  const [key, setKey] = useState(null);
  const [email, setEmail] = useState(null);
  const [schoolGroups, setSchoolGroups] = useState([]);
  const [schoolStudents, setSchoolStudents] = useState([]);
  const [schoolFiles, setSchoolFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingFiles, setLoadingFiles] = useState(true);

  const keys = ["3mgAjfCVRKhBxzJDfv5KADEk", "aEPG9XgCFwEV84YSn9scZvBU", "f6nKaUBWsVaLMPNRNF7KXzSf", "bnMvzDWyftN9dTZEMLmz34xQ", "fPPjJ9mvfFkDdvBRmm3VwX6N", "MAads83jVfEKjGPrJmaAfznq", "3PB2SXfNhsTK8Cy6uyur3nsR", "2Brs3wzcZtgnmXyzkFVXq8Ud", "DzG3fWQcUMeCuT533szzkmBc", "eAYUM2kN2nYx86yLa7HJj9yT", "SExjpeMrV6tjJLJrqmKek5tx", "HBR3AvxNAwCTcLavjNHsWfBt", "2mAK523GTpHQaMXXx3vRbLX3", "DJfWUYAkXdJXst5zxwpLNAhv", "3gB4ZjGB2CMY22kH6zm8gcx4", "9KnZcBKXBy4VsvCfqb6bWw9f", "Hbwn3Fk32B9h9R95nfXwcAzT", "s5Sq9JJGZDqX24wn9DZZKFzb", "egmb96GxGG464Gz4TXzrD5mb", "DWHrBvGBpe2xwRLqCdf3QrJk"];
  const excludedGroups = ['Demo', '0 Demo', 'Demo 0', 'demo', 'none'];

  const postData = async (url, headers, body) => {
    let result = { status: 400, data: {} }
    try {
        const response = await axios.post(url, body, headers);
        result.status = 200;
        result.data = response.data;
    } catch (error) {
        result.statusCode = error.status;
        result.data = error;
    }
    return result;
}




  const validateAppMode = async (qSchool, qKey, qEmail, qShowLists) => {
    if (!_.isEmpty(qSchool) && !_.isNull(qSchool)) setSchool(qSchool);
    if (!_.isEmpty(qKey) && !_.isNull(qKey)) setKey(qKey);
    if (_.isEmpty(qKey) || _.isNull(qKey)) setKey(false);
    if (!_.isEmpty(qEmail) && !_.isNull(qEmail)) setEmail(qEmail);
    if (!_.isEmpty(qShowLists) && !_.isNull(qShowLists)) setShowLists(qShowLists);

    if (!_.isNull(qSchool) && !_.isNull(qKey) && !_.isNull(qEmail)) {
      if (!_.isEmpty(qShowLists) && !_.isNull(qShowLists)) {
        let currentSL = qShowLists;
        if (currentSL === "true") {
          setShowLists(true);
        } else {
          setShowLists(false);
        }
      }
      
      const allProjectTenants = (await postData('https://us-central1-lecxitmath.cloudfunctions.net/tempGetProjectTenantIds', { }, {"data": {"scope":"all", "project":"pen"}}))['data'];
      //const allProjectTenants = await httpsCallable(functions, 'tempGetProjectTenantIds')({ scope: 'all', token: "c1ac2d20-19a3-401f-a3a1-f5f29ef91ba4" });

      if (_.includes(allProjectTenants.data, qSchool)) {
        const tenantInfo = (await postData('https://us-central1-lecxitmath.cloudfunctions.net/tempGetSingleTenantDefinition', { }, {"data": {"tenant":qSchool, "project":"pen"}}))['data'];
        
        if (_.some(tenantInfo.data.users, (value) => value.email === qEmail)) {
          if (_.includes(keys, qKey)) {
            let currentGroups = tenantInfo.data.groups;
            let definitiveGroups = [];
            let processedTenantFiles = {};

            for (let i = 0; i < currentGroups.length; i++) {
              if (!_.includes(excludedGroups, currentGroups[i])) {
                definitiveGroups.push(currentGroups[i]);
                let newObject = { group: currentGroups[i], attendanceList: 0, gradesLists: 0, others: 0, attedanceGradesList: 0, qualitativeReport: 0, total: 0 };
                processedTenantFiles[currentGroups[i]] = newObject;
              }
            }

            let currentStudents = _.groupBy(tenantInfo.data.students, "group");
            definitiveGroups.push('Un altres grup');
            processedTenantFiles['Un altre grup'] = { group: 'Un altre grup', attendanceList: 0, gradesLists: 0, attedanceGradesList: 0, others: 0, qualitativeReport: 0, total: 0 };
            definitiveGroups.push('Tots els grups');
            processedTenantFiles['Tots els grups'] = { group: 'Tots els grups', attendanceList: 0, gradesLists: 0, attedanceGradesList: 0, others: 0, qualitativeReport: 0, total: 0 };

            setSchoolGroups(definitiveGroups);
            setSchoolStudents(currentStudents);

            httpsCallable(functions, 'tempGetTenantFiles')({ tenant: qSchool }).then((response) => {
              console.log('Response:', response);
              let allTenantFiles = response.data && response.data.files ? response.data.files : [];
              console.log('All Tenant Files:', allTenantFiles);

              // Process files for the school
              processSchoolFiles(allTenantFiles, processedTenantFiles, definitiveGroups);
            });

            setLoadingFiles(false);
            setAppMode(2);
          } else {
            setAppMode(1);
          }
        } else {
          setAppMode(1);
        }
      } else {
        setAppMode(1);
      }
    }
    setLoading(false);
  };

  const processSchoolFiles = (allTenantFiles, processedTenantFiles, schoolGroups) => {
    allTenantFiles.forEach((currentFile) => {
      if (currentFile !== '') {
        let splitInfo = currentFile.split('/');
        if (splitInfo.length > 1 && splitInfo[1] !== '') {
          let [group, fileType] = splitInfo;
          if (schoolGroups.includes(group)) {
            if (processedTenantFiles[group]) {
              let currentObject = { ...processedTenantFiles[group] };
              console.log(fileType.split('_')[fileType.split('_').length - 2]);
              switch (fileType.split('_')[fileType.split('_').length - 2]) {
                case 'al':
                  currentObject.attendanceList++;
                  break;
                case 'gl':
                  currentObject.gradesLists++;
                  break;
                case 'ot':
                  currentObject.others++;
                  break;
                case 'ag':
                  currentObject.attedanceGradesList++;
                  break;
                case 'qr':
                  currentObject.qualitativeReport++;
                  break;
              }
              currentObject.total++;
              processedTenantFiles[group] = currentObject;
            }
          }
        }
      }
    });


    setSchoolFiles(_.orderBy(_.values(processedTenantFiles), 'group'));
  };

  const refreshSchoolFiles = async () => {
    setLoadingFiles(true);

    try {
      const response = await httpsCallable(functions, 'tempGetTenantFiles')({ tenant: school });
      const allTenantFiles = response.data && response.data.files ? response.data.files : [];

      let processedTenantFiles = {};
      let definitiveGroups = schoolGroups.slice(); // Make a copy of schoolGroups array

      for (let i = 0; i < definitiveGroups.length; i++) {
        if (!_.includes(excludedGroups, definitiveGroups[i])) {
          processedTenantFiles[definitiveGroups[i]] = { group: definitiveGroups[i], attendanceList: 0, gradesLists: 0, others: 0, attedanceGradesList: 0, qualitativeReport: 0, total: 0 };
        }
      }

      processSchoolFiles(allTenantFiles, processedTenantFiles, definitiveGroups);

      setLoadingFiles(false);
    } catch (error) {
      console.error('Error refreshing school files:', error);
      setLoadingFiles(false);
    }
  };




  const headerStyle = {

    color: '#fff',
    height: 64,
    paddingInline: 20,
    lineHeight: '64px',
    backgroundColor: '#112954',
  };
  const contentStyle = {

    minHeight: 120,
    lineHeight: '120px',
    color: '#fff',
    paddingRight: '20px',
    paddingLeft: '20px',
    paddingBottom: '20px'

    //backgroundColor: '#108ee9',
  };

  const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#112954',
  };

  const getGroupsInfo = () => {
    // Sort groups alphabetically
    const sortedGroups = _.orderBy(schoolGroups, [group => group.toLowerCase()]);

    let response = [];
    for (let i = 0; i < sortedGroups.length - 2; i++) {
      let groupArray = schoolStudents[sortedGroups[i]];
      let object = {
        label: sortedGroups[i],
        key: sortedGroups[i],
        children: (
          <Table
            columns={[
              {
                title: 'Nom',
                dataIndex: 'name',
                key: 'name',
              },
              {
                title: 'Consentiment',
                key: 'consentiment',
                sortDirections: ['descend'],
                render: (_, record) => (
                  <>
                    {!record.consentReceived ? (
                      <Tag color="blue">No rebut</Tag>
                    ) : (
                      <>
                        {record.noConsent ? (
                          <Tag color="red">No consentit</Tag>
                        ) : (
                          <Tag color="green">Consentit</Tag>
                        )}
                      </>
                    )}
                  </>
                ),
              },
            ]}
            dataSource={_.orderBy(groupArray, ['name'])}
          />
        ),
      };
      response.push(object);
    }
    return response;
  }

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const qSchool = queryParameters.get("school");
    const qKey = queryParameters.get("key");
    const qEmail = queryParameters.get("email");
    const qShowLists = queryParameters.get("sl");
    validateAppMode(qSchool, qKey, qEmail, qShowLists);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState('');
  const showModal = (group) => {
    setSelectedGroup(group)
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getSchoolGroup = (groupCode) => {
    let response = {}
    for (let i = 0; i < schoolFiles.length && _.isEmpty(response); i++) {
      let currentGroup = schoolFiles[i];
      if (currentGroup.group === groupCode) {
        response = currentGroup;
      }
    }
    return response;
  }
  return (
    <Layout>
      <Header style={headerStyle}><img src={logo} alt="Logo" style={{ height: '60px', width: 'auto' }} /></Header>
      {loading ?
        (<>
          <Row align='middle' justify='center' style={{ height: 'calc(100vh - 250px)' }}>
            <Col>
              <Spin size="large" />
            </Col>
          </Row>

        </>) :
        (<>
          <Content style={contentStyle}>
            <Row gutter={16}>
              <Col span={12}>
                <Typography>
                  <Title level={3}>Suport Educatiu</Title>
                  <Paragraph>
                    Suport Educatiu. Acompanyament per Accelerar Oportunitats és un projecte que busca millorar les oportunitats educatives de l’alumnat vulnerable a través de tres programes que treballen competències matemàtiques, de comprensió lectora i habilitats socioemocionals. El projecte busca avaluar l’impacte de cadascun d’aquests programes a través d’una avaluació rigorosa que ens permetrà saber què és allò que funciona millor.
                  </Paragraph>
                  <Paragraph>
                    La iniciativa és finançada amb Fons Next Generation de la Unió Europea vehiculats a través del Pla de Recuperació, Transformació i Resiliència del Ministeri d’Inclusió, Seguretat Social i Migracions.
                  </Paragraph>
                </Typography>
              </Col>
              <Col span={12}>
                <Typography>
                  <Title level={3}>Per què demanem aquestes dades?</Title>

                  <Paragraph>El nostre objectiu principal és avaluar l'impacte real de cadascun dels nostres programes educatius. Entendre com estan progressant els estudiants i com es beneficien de les nostres iniciatives és fonamental per a assegurar-nos que estem brindant una educació de qualitat que millori les seves vides.</Paragraph>

                  <Paragraph>En recopilar registres de notes i dades d'assistència dels alumnes, obtenim informació valuosa que ens permet realitzar una anàlisi en profunditat. Aquestes dades ens ajuden a examinar l'impacte específic que cada programa té en el rendiment acadèmic dels estudiants i la seva participació activa en les classes.</Paragraph>

                  <Paragraph>Entenem que la privacitat i seguretat de les dades són de summa importància. Per això, garantim que tota la informació recopilada es tractarà de manera confidencial i s'utilitzarà exclusivament amb finalitats d'avaluació. Si tens dubtes pots veure la nostra <Link href='https://suporteducatiu.org/politica-de-privacitat/'>política de privacitat.</Link></Paragraph>
                </Typography>
              </Col>
            </Row>
            {appMode === 1 && (
              <Card style={{ width: '100%' }}>
                <Result
                  status="500"
                  title="Error"
                  subTitle="Ho sentim però alguna cosa ha fallat amb el link, si tens algun dubte pots escriure'ns a:"

                />
              </Card>
            )}
            {appMode === 2 && (

              <Row gutter={16}>
                {showLists && (
                  <Col span={12}>
                    <center>
                      <Card
                        title="Llistats d'estudiants i informació dels seus consentiments"
                        bordered={false}
                        style={{
                          width: '100%',
                        }}
                      >
                        <Tabs tabPosition={'left'} items={getGroupsInfo()} />
                      </Card>
                    </center>
                  </Col>
                )}
                <Col span={showLists ? 12 : 24}>
                  <center>
                    <Card
                      title="Pujar fitxers per classe"
                      bordered={false}
                      style={{
                        width: '100%',
                      }}
                    >
                      {!loadingFiles && (<Table columns={[{ title: 'Grup', dataIndex: 'group', key: 'group' }, { title: 'Número de fitxers', dataIndex: 'total', key: 'total' }, {
                        title: '',
                        key: 'action',
                        render: (_, record) => (
                          <a onClick={() => showModal(record)}>Pujar fitxer</a>
                        ),
                      }]} dataSource={schoolFiles} />)}
                    </Card>
                  </center>

                </Col>
              </Row>

            )}
          </Content>
        </>)}
      <Modal footer={null} title={'Fitxer de: ' + selectedGroup.group} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <MyForm school={school} selectedGroup={selectedGroup} refreshSchoolFiles={refreshSchoolFiles} onOk={handleOk} getSchoolGroup={getSchoolGroup} />
      </Modal>


      <Footer style={footerStyle}><img src={footer} alt="Footer" style={{ height: '150px', width: 'auto' }} /></Footer>
    </Layout>

  );
}

export default App;
